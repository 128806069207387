import React from "react"
import {Link} from "gatsby"
import SiteLayout from "../components/sitelayout"
import Header from "../components/header"
import SEO from "../components/seo"
import Button from "../components/button"
import Container from "../components/container"
import TextDiv from "../components/textdiv"
import Section from "../components/section"
import Segment, {SegmentSummary, SegmentSubTitle} from "../components/segment"
import HelpLink from "../components/helplink"

import { useTheme } from "../services/theme"

const Dpa = () => {

    const theme = useTheme()
    return (
        <SiteLayout
        signup={
            <Link to="/tools/hubspot-webflow-form-tool"><Button secondary="true">Get free tools</Button></Link>
        }
        >
            <SEO title="Data processing agreement"/>
            <Container>
                <Header
                paddingTop="20px"
                title="Data processing agreement"
                align="left"
                />
            </Container>
            <Section>
                <p>
                    This Data Processing Agreement and its Annexes (“DPA”) reflect the terms with respect to the Processing of Personal Data
                     by us on behalf of you in connection with the Services
                     under the <HelpLink to="/terms" display="inline" fontWeight="600">terms of use</HelpLink>
                     between you and us (also referred to in this DPA as the “Terms”).
                     This DPA is supplemental to, and forms an integral part of the Terms and is effective upon its incorporation into the Terms.
                     In case of any conflict or inconsistency with the Terms, this DPA will take precedence
                     over the Terms to the extent of such conflict or inconsistency.
                </p>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            What we mean when we say...
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Definitions</SegmentSubTitle>
                    <p>
                        "California Personal Information" means Personal Data that is subject to the protection
                         of the California Consumer Privacy Act of 2018("CCPA").
                    </p>
                    <p>
                        "Consumer", "Business", "Sell" and "Service Provider" will have the meanings given to them in the CCPA.
                    </p>
                    <p>
                        “Controller” means the natural or legal person, public authority, agency or other body which, alone or jointly with others,
                         determines the purposes and means of the Processing of Personal Data.
                    </p>
                    <p>
                        “Data Protection Laws” means all applicable worldwide legislation relating to data protection
                         and privacy which applies to the respective party in the role of Processing Personal Data in question under the Terms,
                         including without limitation European Data Protection Laws, the CCPA and the data protection and
                         privacy laws of Australia and Singapore; in each case as amended, repealed, consolidated or replaced from time to time.
                    </p>
                    <p>
                        “Data Subject” means the individual to whom Personal Data relates.
                    </p>
                    <p>
                        "Europe" means the European Union, the European Economic Area and/or their member states, Switzerland and the United Kingdom.<br/><br/>
                        “European Data” means Personal Data that is subject to the protection of European Data Protection Laws.
                    </p>
                    <p>
                        "European Data Protection Laws" means data protection laws applicable in Europe,
                         including each case below, as they may be amended, superseded or replaced:<br/>
                        <ol type="i">
                            <li>
                                General Data Protection Regulation("GDPR") - Regulation 2016/679 of the European Parliament
                                 and of the Council on the protection of natural persons with regard to the processing of personal
                                 data and on the free movement of such data
                            </li>
                            <li>
                                Directive 2002/58/EC concerning the processing of personal data and the protection of privacy
                                 in the electronic communications sector
                            </li>
                            <li>
                                Applicable national implementations of (i) and (ii); or in respect of the United Kingdom,
                                 any applicable national legislation that replaces or converts in domestic law the GDPR or any other law
                                 relating to data and privacy as a consequence of the United Kingdom leaving the European Union 
                            </li>
                            <li>
                                Swiss Federal Data Protection Act on 19 June 1992 and its Ordinance <br/> 
                            </li>
                        </ol>
                    </p>
                    <p>
                        “Instructions” means the written, documented instructions issued by a Controller to a Processor,
                         and directing the same to perform a specific or general action with regard to Personal Data
                         (including, but not limited to, depersonalizing, blocking, deletion, making available).
                    </p>
                    <p>
                        "Permitted Affiliates" means any of your Affiliates that
                        <ol type="i">
                            <li>
                                are permitted to use the Services pursuant to the Terms
                            </li>
                            <li>
                                qualify as a Controller of Personal Data Processed by us
                            </li>
                            <li>
                                are subject to European Data Protection Laws
                            </li>
                        </ol>
                    </p>
                    <p>
                        “Personal Data” means any information relating to an identified or identifiable individual
                         where such information is contained within Customer Data and is protected similarly as personal data,
                         personal information or personally identifiable information under applicable Data Protection Laws.
                    </p>
                    <p>
                        “Personal Data Breach” means a breach of security leading to the accidental or unlawful destruction, loss,
                         alteration, unauthorized disclosure of, or access to, Personal Data transmitted, stored or otherwise Processed by us
                         and/or our Sub-Processors in connection with the provision of the Services.
                         This does not include unsuccessful attempts or activities that do not compromise
                         the security of Personal Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks,
                         and other network attacks on firewalls or networked systems.
                    </p>
                    <p>
                        “Processing” means any operation or set of operations which is performed on Personal Data,
                         encompassing the collection, recording, organization, structuring, storage, adaptation or alteration,
                         retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available,
                         alignment or combination, restriction or erasure of Personal Data.
                         The terms “Process”, “Processes” and “Processed” will be construed accordingly.
                    </p>
                    <p>
                        “Processor” means a natural or legal person, public authority, agency or other body which Processes
                         Personal Data on behalf of the Controller.
                    </p>
                    <p>
                        “Standard Contractual Clauses” means the standard contractual clauses for
                         Processors approved pursuant to the European Commission’s decision (C(2010)593) of 5 February 2010,
                         in the form set out at Annex 3; as may be amended, superseded or replaced.
                    </p>
                    <p>
                        “Sub-Processor” means any Processor engaged by us or our Affiliates to assist in fulfilling
                         our obligations with respect to the provision of the Services under the Terms.
                         Sub-Processors may include third parties or our Affiliates but will exclude our employees or consultants.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You must comply with the law.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Your responsibilities</SegmentSubTitle>
                    <p>
                        Compliance with Laws:<br/>
                        Within the scope of the Terms and use of the Services,
                         you will be responsible for complying with all requirements that apply under applicable
                         Data Protection Laws with respect to Processing of Personal Data and the Instructions issued to us.
                         You are solely responsible for:<br/>
                         <ol type="i">
                             <li>
                                the accuracy, quality, and legality of Customer Data and the means by which you acquired Personal Data
                             </li>
                             <li>
                                complying with all necessary transparency and lawfulness requirements under applicable
                                 Data Protection Laws for the collection and use of the Personal Data,
                                 including obtaining any necessary consents and authorizations (particularly for marketing purposes)
                             </li>
                             <li>
                                ensuring you have the right to transfer, or provide access to, the Personal Data to us for Processing
                                 in accordance with the Terms (including this DPA)
                             </li>
                             <li>
                                ensuring that your Instructions to us regarding the Processing of Personal Data comply with applicable laws,
                                 including Data Protection Laws
                             </li>
                             <li>
                                complying with all laws (including Data Protection Laws) applicable to any emails or other content created,
                                 sent or managed through the Services, including those relating to obtaining consents
                                 (where required) to send emails, the content of the emails and its email deployment practices.
                             </li>
                         </ol>
                        You will inform us without undue delay if you are not able to comply with the responsibilities under this section
                         or applicable Data Protection Laws.
                    </p>
                    <p>
                        Controller Instructions: <br/>
                        The parties agree that the Terms (including this DPA),
                         together with your use of the Service in accordance with the Terms,
                         constitute your complete and final Instructions to us in relation to the Processing of Personal Data.
                         Additional instructions outside the scope of the Instructions shall require prior written agreement between us and you.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We will protect data and comply with the law.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Our responsibilities</SegmentSubTitle>
                    <p>
                        Compliance with Instructions:<br/>
                        We will only Process Personal Data for the purposes described in this DPA or as otherwise agreed within
                         the scope of your lawful Instructions, except where and to the extent otherwise required by applicable law.
                         We are not responsible for compliance with any Data Protection Laws applicable to you or your industry
                         that are not generally applicable to us.
                    </p>
                    <p>

                        Conflict of Laws:<br/> If we become aware that we cannot Process Personal Data in accordance with your
                         Instructions due to a legal requirement under any applicable law, we will promptly notify you of that legal
                         requirement to the extent permitted by the applicable law and where necessary,
                         cease all Processing (other than merely storing and maintaining the security of the affected Personal Data)
                         until such time as you issue new Instructions with which we are able to comply.
                         If this provision is invoked, we will not be liable to you under the Terms for
                         any failure to perform the applicable Services until such time as you issue
                         new lawful Instructions with regard to the Processing.
                    </p>
                    <p>
                        Security:<br/>We will implement and maintain appropriate security measures to
                         protect Personal Data from Personal Data Breaches, as described under Annex 2 to this DPA ("Security Measures").
                         We may modify or update the Security Measures at our discretion provided that such modification
                         or update does not result in a material degradation in the protection offered by the Security Measures.
                    </p>
                    <p>
                        Confidentiality:<br/>We will ensure that any personnel whom we authorize to Process Personal Data
                         on our behalf is subject to appropriate confidentiality obligations (whether a contractual
                         or statutory duty) with respect to that Personal Data.
                    </p>
                    <p>
                        Personal Data Breaches:<br/>We will notify you without undue delay after we become aware of any
                         Personal Data Breach and will provide timely information relating to the Personal Data Breach
                         as it becomes known or reasonably requested by you. At your request, we will promptly provide
                         you with such reasonable assistance as necessary to enable you to notify relevant Personal Data Breaches
                         to competent authorities and/or affected Data Subjects, if you are required to do so under Data Protection Laws.
                    </p>
                    <p>
                        Deletion of Personal Data:<br/>You may request the deletion of your Vimkit account at any time
                         as specified in the Terms. We will delete all Customer Data, including Personal Data
                         Processed pursuant to this DPA, on termination of your account in accordance with the procedures and
                         timeframes set out in the Terms. This requirement shall not apply to the extent we are required
                         by applicable law to retain some or all of the Customer Data.  
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We'll help you respond to requests when needed.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Data subject requests</SegmentSubTitle>
                    <p>
                        To the extent that you are unable to independently address a Data Subject Request through the Service,
                         then upon your written request to legal@vimkit.com we will provide reasonable assistance to you to respond
                         to any Data Subject Requests or requests from data protection authorities relating to the Processing of Personal Data
                         under the Terms. You shall reimburse us for reasonable costs arising from this assistance.
                    </p>
                    <p>
                        If a Data Subject Request or other communication regarding the Processing of Personal Data under the Terms is made directly to us,
                         we will promptly inform you and will advise the Data Subject to submit their request to you.
                         You will be solely responsible for responding substantively to any such Data Subject Requests or
                         communications involving Personal Data.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Our sub-processors protect your data too.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Sub-processors</SegmentSubTitle>
                    <p>
                        You agree that we may engage Sub-Processors to Process Personal Data on your behalf.
                         We have currently appointed, as Sub-Processors, the third parties listed in Annex 4 to this DPA.
                         We require data protection terms on the Sub-Processors that provide at least the same level of protection
                         for Personal Data as those in this DPA (including, where appropriate, the Standard Contractual Clauses),
                         to the extent applicable to the nature of the services provided by such Sub-Processors.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We process and transfer data globally.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Data transfers</SegmentSubTitle>
                    <p>
                        You acknowledge and agree that we may access and Process Personal Data
                         on a global basis as necessary to provide the Service in accordance with the Terms,
                         and in particular that Personal Data will be transferred to and processed by us in the United States
                         and to other jurisdictions where our Affiliates and Sub-Processors have operations.
                         We will ensure such transfers are made in compliance with the requirements of Data Protection Laws.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Conditions that apply only to European Data
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Additional provisions for European data</SegmentSubTitle>
                    <p>
                        Roles of the Parties:<br/>
                        When Processing European Data in accordance with your Instructions,
                         you agree that you are the Controller of European Data and we are the Processor.
                    </p>
                    <p>
                        Instructions:<br/>
                        If we believe that your Instruction infringes European Data Protection Laws (where applicable),
                         we will inform you without delay.
                    </p>
                    <p>
                        Notification and Objection to New Sub-Processors:<br/>
                        We will notify you of any changes to Sub-processors by updating Annex 4 to this DPA
                         and will give you the opportunity to object to the engagement of the new Sub-Processor
                         on reasonable grounds relating to the protection of Personal Data within 7 days after updating Annex 4 to this DPA.
                         If you do notify us of such an objection, we will discuss with you in good faith with a view to achieving a reasonable resolution.
                         If no such resolution can be reached, we will, at our sole discretion, either not appoint the new Sub-Processor,
                         or permit you to suspend or terminate the Service in accordance with the Terms without liability to either party.
                         You will still be responsible for any fees incurred by you prior to termination.
                    </p>
                    <p>
                        Data Protection Impact Assessments and Consultation with Supervisory Authorities:<br/>
                        To the extent that the required information is reasonably available to us,
                         and you do not otherwise have access to the required information, we will provide reasonable assistance to you with
                         any data protection impact assessments, and prior consultations with supervisory authorities
                         or other competent data privacy authorities to the extent required by European Data Protection Laws.
                    </p>
                    <p>
                        Transfer Mechanisms for Data Transfers:
                        <ul>
                            <li>
                                We will not transfer European Data to any country or recipient not recognized as providing an adequate level
                                 of protection for Personal Data (within the meaning of applicable European Data Protection Laws),
                                 unless we first take all such measures as are necessary to ensure the transfer is in compliance with
                                 applicable European Data Protection Laws. Such measures may include (without limitation)
                                 transferring such data to a recipient that is covered by a suitable framework
                                 or other legally adequate transfer mechanism recognized by the relevant authorities or courts as providing
                                 an adequate level of protection for Personal Data, to a recipient that has achieved
                                 binding corporate rules authorization in accordance with European Data Protection Laws,
                                 or to a recipient that has executed appropriate standard contractual clauses in each case as adopted
                                 or approved in accordance with applicable European Data Protection Laws.
                            </li>
                            <li>
                                You acknowledge that in connection with the performance of the Services,
                                 we are a recipient of European Data in the United States.
                                 We agree to abide by and process European Data in compliance with the Standard Contractual Clauses.
                            </li>
                            <li>
                                You agree that for the purposes of the descriptions in the Standard Contractual Clauses,
                                 we will be deemed the "data importer" and you will be deemed the "data exporter"
                                 (even if you yourself are located outside Europe and/or are acting as a processor on behalf of third party controllers).
                                 Where the contracting entity under the Terms is a third-party, you provide such contracting entity with a mandate to enter
                                 into the Standard Contractual Clauses with us. Such contracting entity (not us) will remain fully and solely responsible
                                 and liable to you for the performance of the Standard Contractual Clauses and you will direct any instructions,
                                 claims or enquiries in relation to the Standard Contractual Clauses to such contracting entity.
                                 If and to the extent the Standard Contractual Clauses (where applicable) conflict with any provision of this DPA,
                                 the Standard Contractual Clauses will prevail to the extent of such conflict.
                            </li>
                        </ul>
                        {/*
                        Demonstration of Compliance:<br/>
                        We will make all information reasonably necessary to demonstrate compliance with this DPA available to you.
                         You acknowledge that the Service is hosted by our sub-processors who maintain independently validated security programs
                         (including SOC 2 and ISO 27001). Further, at your written request, we will provide responses (on a confidential basis)
                         to all reasonable requests for information made by you necessary to confirm our compliance with this DPA,
                         provided that you will not exercise this right more than once per calendar year.
                        */}
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Conditions that apply only to California personal information
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Additional provisions for California personal information</SegmentSubTitle>
                    <p>
                        Roles:<br/>
                        When processing California Personal Information in accordance with your Instructions,
                         you agree that you are a Business and we are a Service Provider for the purposes of the CCPA.
                    </p>
                    <p>
                        Responsibilities: <br/>
                        You agree that we will Process California Personal Information as a Service Provider
                         strictly for the purpose of performing the Services under the Terms (the "Business Purpose")
                         or as otherwise permitted by the CCPA.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We can make changes to the DPA.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Amendments, severability, limitation of liability and governing law</SegmentSubTitle>
                    <p>
                        We reserve the right to make any updates and changes to this DPA.
                         If any individual provisions of this DPA are determined to be invalid or unenforceable,
                         the validity and enforceability of the other provisions of this DPA will not be affected.
                         Each party to this DPA and the Standard Contractual Clauses (where applicable), whether in contract,
                         tort or under any other theory of liability, will be subject to the limitations and exclusions of liability set out in the Terms.  
                         This DPA will be governed by applicable law specified in the Terms, unless required otherwise by Data Protection Laws.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            The DPA applies to you and your affiliates.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Parties to the DPA</SegmentSubTitle>
                    <p>
                        Permitted Affiliates:<br/>
                        By agreeing to the Terms, you enter into this DPA on behalf of yourself and,
                         to the extent required under applicable Data Protection Laws, in the name and on behalf of your Permitted Affiliates,
                         thereby establishing a separate DPA between us and each such Permitted Affiliate subject to the Terms
                         and the ‘General Provisions’ and ‘Parties to this DPA’ sections of this DPA.
                         Each Permitted Affiliate agrees to be bound by the obligations under this DPA and,
                         to the extent applicable, the Terms. For the purposes of this DPA only, and except where indicated otherwise,
                         the terms “you” and “your” will include you and such Permitted Affiliates. 
                    </p>
                    <p>
                        Authorization:<br/>
                        By agreeing to this DPA you represent that you ate authorized to agree to and enter into this DPA for and on behalf of yourself
                         and, as applicable, each of your Permitted Affiliates.
                    </p>
                    <p>
                        Remedies:<br/>
                        Except where applicable Data Protection Laws require a Permitted Affiliate to exercise a right
                         or seek any remedy under this DPA against us directly by itself, you agree that only the entity that is the contracting party
                         to the Terms will exercise any right or seek any remedy any Permitted Affiliate may have under
                         this DPA on behalf of its Affiliates, and the entity that is the contracting party to the Terms
                         will exercise any such rights under this DPA not separately for each Permitted Affiliate individually
                         but in a combined manner for itself and all of its Permitted Affiliates together.
                         You are responsible for coordinating all communication with us under the DPA and will be entitled to make
                         and receive any communication related to this DPA on behalf of your Permitted Affiliates.
                         You agree that you will, take all reasonable measures to limit any impact on us and our Affiliates.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Details of how we process your data.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Annex 1 - Details of processing</SegmentSubTitle>
                    <p>
                        Nature and purpose of processing:<br/>
                        We will Process Personal Data as necessary to provide the Services pursuant to the Terms 
                    </p>
                    <p>
                        Duration of processing:<br/>
                        Subject to the 'Deletion of Personal Data' section of this DPA, we will Process Personal Data as long as we provide the Service to you. 
                    </p>
                    <p>
                        Categories of data subjects:<br/>
                        You may submit Personal Data in the course of using the Service, the extent of which is determined and controlled by you
                         in your sole discretion, and which includes the following categories of Data Subjects:
                         Your end users including your employees, contractors, collaborators, customers, prospects, suppliers and subcontractors.
                         Data Subjects may also include individuals attempting to communicate with or transfer Personal Data to your end users.
                    </p>
                    <p>
                        Categories of personal data:<br/>
                        You may submit Personal Data to the Services, the extent of which is determined and controlled by you in your sole discretion,
                         and which may include but is not limited to the following categories of Personal Data: 
                        <ul>
                            <li>
                                Contact information
                            </li>
                            <li>
                                Any other Personal Data submitted by, sent to, or received by you, or your end users, via the Service
                            </li>
                        </ul>
                    </p>
                    <p>
                        Special categories of data:<br/>
                        None
                    </p>
                    <p>
                        Processing operations:<br/>
                        Personal Data will be Processed in accordance with the Terms (including this DPA) and may be subject to the following
                         Processing activities:
                        <ul>
                            <li>
                                Storage and other Processing necessary to provide, maintain and improve the Services provided to you
                            </li>
                            <li>
                                Disclosure in accordance with the Terms (including this DPA) and/or as compelled by applicable laws
                            </li>
                        </ul>
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Details of how we keep your data safe.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Annex 2 - Security measures</SegmentSubTitle>
                    <p>
                        Access control:<br/>
                        <ul>
                            <li>
                                Cloud Infrastructure: We host our Service with outsourced cloud infrastructure providers. 
                                 Additionally, we maintain contractual relationships with vendors in order to provide the Service in accordance with our DPA.
                                 We rely on contractual agreements and privacy policies in order to protect data processed or stored by these vendors. 
                            </li>
                            <li>
                                Authentication: We use an outsourced authentication provider for our Service.
                                 Customers who interact with the Service via the user interface must authenticate before accessing non-public Customer Data.
                            </li>
                            <li>
                                Authorization: Customer Data is stored in cloud-based storage systems accessible to Customers
                                 via only application user interfaces and application programming interfaces.
                                 Customers are not allowed direct access to the underlying application infrastructure.
                                 The authorization model in our Service is designed to ensure that only the appropriately
                                 assigned individuals can access relevant features, views, and customization options.
                                 Authorization to data sets is performed through validating the user’s permissions
                                 against the attributes associated with each data set.
                            </li>
                            <li>
                                Application Programming Interface (API) access: Public APIs may be accessed using an API key or through Oauth authorization
                            </li>
                        </ul> 
                    </p>
                    <p>
                        Transmission control:<br/>
                        <ul>
                            <li>
                                In-transit: We enforce HTTPS encryption (also referred to as SSL or TLS) on our Service.
                                 Our HTTPS implementation uses industry standard algorithms and certificates. 
                            </li>
                            <li>
                                At-rest: We have implemented technologies to ensure that stored data is encrypted at rest. 
                            </li>
                        </ul> 
                    </p>
                    <p>
                        Input control:<br/>
                        <ul>
                            <li>
                                Detection: We designed our infrastructure to log extensive information about the system behavior,
                                 traffic received, system authentication, and other application requests.
                                 Internal systems aggregated log data and alert appropriate employees of malicious, unintended,
                                 or anomalous activities. Our personnel, including security, operations, and support personnel,
                                 are responsive to known incidents. 
                            </li>
                            <li>
                                Response and tracking: We maintain a record of known security incidents that includes description,
                                 dates and times of relevant activities, and incident disposition.
                                 Suspected and confirmed security incidents are investigated by security, operations,
                                 or support personnel; and appropriate resolution steps are identified and documented.
                                 For any confirmed incidents, we will take appropriate steps to minimize product and Customer damage
                                 or unauthorized disclosure. Notification to you will be in accordance with the Terms. 
                            </li>
                        </ul>                    
                    </p>
                    <p>
                        Availability control:<br/>
                        <ul>
                            <li>
                                Fault tolerance: Backup and replication strategies are designed to ensure redundancy and fail-over protections
                                 during a significant processing failure. 
                            </li>
                            <li>
                                Online replicas and backups: All databases are backed up and maintained using at least industry standard methods.
                                 Our Service is designed to ensure redundancy and seamless failover.
                                 The server instances that support the Service are also architected with a goal to prevent single points of failure.
                                 This design assists our operations in maintaining and updating the Service while limiting downtime.
                            </li>
                        </ul>                    
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Details of how we transfer data to other countries.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Annex 3 - Standard contractual clauses</SegmentSubTitle>
                    <p>
                        For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors
                         established in third countries which do not ensure an adequate level of data protection, <br/>
                        The Customer, as defined in the Terms (the “data exporter”)<br/>
                        And<br/>
                        Jensa Ventures Inc.,
                         2093 Philadelphia Pike #8070 Claymont DE 19703 (the “data importer”)<br/>
                        each a ‘party’; together ‘the parties’,<br/>
                         HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with
                         respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer
                         by the data exporter to the data importer of the personal data specified in Appendix 1. 
                    </p>
                    <p>
                        <b>Clause 1 - Definitions</b><br/> 
                        For the purposes of the Clauses:
                        <ol type="a">
                            <li>
                                ‘personal data’, ‘special categories of data’, ‘process/processing’, ‘controller’, ‘processor’,
                                 ‘data subject’ and ‘supervisory authority’ shall have the same meaning as in Directive 95/46/EC
                                 of the European Parliament and of the Council of 24 October 1995 on the protection of individuals
                                 with regard to the processing of personal data and on the free movement of such data;
                            </li>
                            <li>
                                ‘the data exporter’ means the controller who transfers the personal data;
                            </li>
                            <li>
                                ‘the data importer’ means the processor who agrees to receive from the data exporter personal data
                                 intended for processing on his behalf after the transfer in accordance with his instructions
                                 and the terms of the Clauses and who is not subject to a third country’s system ensuring adequate
                                 protection within the meaning of Article 25(1) of Directive 95/46/EC;
                            </li>
                            <li>
                                'the subprocessor’ means any processor engaged by the data importer or by any other subprocessor
                                 of the data importer who agrees to receive from the data importer or from any other subprocessor
                                 of the data importer personal data exclusively intended for processing activities to be carried
                                 out on behalf of the data exporter after the transfer in accordance with his instructions,
                                 the terms of the Clauses and the terms of the written subcontract;
                            </li>
                            <li>
                                ‘the applicable data protection law’ means the legislation protecting the fundamental rights
                                 and freedoms of individuals and, in particular, their right to privacy with respect to the processing
                                 of personal data applicable to a data controller in the Member State in which the data exporter is established;
                            </li>
                            <li>
                                'technical and organisational security measure’ means those measures aimed at protecting personal data
                                 against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access,
                                 in particular where the processing involves the transmission of data over a network,
                                 and against all other unlawful forms of processing.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 2 - Details of the transfer</b><br/> 
                        The details of the transfer and in particular the special categories of personal data where applicable are specified
                         in Appendix 1 which forms an integral part of the Clauses.
                    </p>
                    <p>
                        <b>Clause 3 - Third party beneficiary clause</b>
                        <ol>
                            <li>
                            The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j),
                             Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary.
                            </li>
                            <li>
                                The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7,
                                 Clause 8(2) and Clauses 9 to 12, in cases where the data exporter has factually disappeared
                                 or has ceased to exist in law unless any successor entity has assumed the entire legal obligations
                                 of the data exporter by contract or by operation of law,
                                 as a result of which it takes on the rights and obligations of the data exporter,
                                 in which case the data subject can enforce them against such entity.
                            </li>
                            <li>
                                The data subject can enforce against the sub-processor this Clause, Clause 5(a) to (e) and (g),
                                 Clause 6, Clause 7, Clause 8(2) and Clauses 9 to 12, in cases where both the data exporter
                                 and the data importer have factually disappeared or ceased to exist in law or have become insolvent,
                                 unless any successor entity has assumed the entire legal obligations of the data exporter by contract
                                 or by operation of law as a result of which it takes on the rights and obligations of the data exporter,
                                 in which case the data subject can enforce them against such entity.
                                 Such third-party liability of the sub-processor shall be limited to its own processing operations under the Clauses.
                            </li>
                            <li>	
                                The parties do not object to a data subject being represented by an association or other body
                                 if the data subject so expressly wishes and if permitted by national law.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 4 - Obligations of the data exporter</b><br/> 
                        The data exporter agrees and warrants:
                        <ol type="a">
                            <li>
                                that the processing, including the transfer itself, of the personal data has been
                                 and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law
                                 (and, where applicable, has been notified to the relevant authorities of the Member State
                                 where the data exporter is established) and does not violate the relevant provisions of that State;
                            </li>
                            <li>
                                that it has instructed and throughout the duration of the personal data-processing services
                                 will instruct the data importer to process the personal data transferred only on the data exporter’s behalf
                                 and in accordance with the applicable data protection law and the Clauses;
                            </li>
                            <li>
                                that the data importer will provide sufficient guarantees in respect of the technical and organisational
                                 security measures specified in Appendix 2 to this contract;
                            </li>
                            <li>
                                that after assessment of the requirements of the applicable data protection law,
                                 the security measures are appropriate to protect personal data against accidental or unlawful destruction
                                 or accidental loss, alteration, unauthorized disclosure or access, in particular where the processing
                                 involves the transmission of data over a network, and against all other unlawful forms of processing,
                                 and that these measures ensure a level of security appropriate to the risks presented by the processing
                                 and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;
                            </li>
                            <li>
                                that it will ensure compliance with the security measures;
                            </li>
                            <li>
                                that, if the transfer involves special categories of data, the data subject has been informed
                                 or will be informed before, or as soon as possible after, the transfer that its data could be transmitted
                                 to a third country not providing adequate protection within the meaning of Directive 95/46/EC;
                            </li>
                            <li>
                                to forward any notification received from the data importer or any sub-processor
                                 pursuant to Clause 5(b) and Clause 8(2) to the data protection supervisory authority
                                 if the data exporter decides to continue the transfer or to lift the suspension;
                            </li>
                            <li>
                                to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2,
                                 and a summary description of the security measures, as well as a copy of any contract for sub-processing services
                                 which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information,
                                 in which case it may remove such commercial information;
                            </li>
                            <li>
                                that, in the event of sub-processing, the processing activity is carried out in accordance with Clause 11
                                 by a sub-processor providing at least the same level of protection for the personal data and the rights of data subject
                                 as the data importer under the Clauses; and
                            </li>
                            <li>
                                that it will ensure compliance with Clause 4(a) to (i).
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 5 - Obligations of the data importer</b><br/> 
                        The data importer agrees and warrants:
                        <ol type="a">
                            <li>
                                to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses;
                                 if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter
                                 of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data
                                 and/or terminate the contract;
                            </li>
                            <li>
                                that it has no reason to believe that the legislation applicable to it prevents it from fulfilling
                                 the instructions received from the data exporter and its obligations under the contract and that in the event
                                 of a change in this legislation which is likely to have a substantial adverse effect on the warranties
                                 and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as
                                 it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
                            </li>
                            <li>
                                that it has implemented the technical and organisational security measures specified in Appendix 2
                                 before processing the personal data transferred;
                            </li>
                            <li>
                                that it will promptly notify the data exporter about:
                                <ol type="i">
                                    <li>
                                        any legally binding request for disclosure of the personal data by a law enforcement authority
                                         unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality
                                         of a law enforcement investigation;
                                    </li>
                                    <li>
                                        any accidental or unauthorized access; and
                                    </li>
                                    <li>
                                        any request received directly from the data subjects without responding to that request,
                                         unless it has been otherwise authorized to do so;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                to deal promptly and properly with all inquiries from the data exporter relating to its processing
                                 of the personal data subject to the transfer and to abide by the advice of the supervisory authority
                                 with regard to the processing of the data transferred;
                            </li>
                            <li>
                                at the request of the data exporter to submit its data-processing facilities for audit of the processing activities
                                 covered by the Clauses which shall be carried out by the data exporter or an inspection body composed
                                 of independent members and in possession of the required professional qualifications bound by a duty
                                 of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;
                            </li>
                            <li>
                                to make available to the data subject upon request a copy of the Clauses, or any existing contract for sub-processing,
                                 unless the Clauses or contract contain commercial information, in which case it may remove such commercial information,
                                  with the exception of Appendix 2 which shall be replaced by a summary description of the security measures
                                 in those cases where the data subject is unable to obtain a copy from the data exporter;
                            </li>
                            <li>
                                that, in the event of sub-processing, it has previously informed the data exporter and obtained its prior written consent;
                            </li>
                            <li>
                                that the processing services by the sub-processor will be carried out in accordance with Clause 11;
                            </li>
                            <li>
                                to send promptly a copy of any sub-processor Terms it concludes under the Clauses to the data exporter.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 6 - Liability</b>
                        <ol>
                            <li>
                                The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations
                                 referred to in Clause 3 or in Clause 11 by any party or sub-processor is entitled to receive compensation
                                 from the data exporter for the damage suffered
                            </li>
                            <li>
                                If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter,
                                 arising out of a breach by the data importer or his sub-processor of any of their obligations referred to in Clause 3
                                 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent,
                                 the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter,
                                 unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law,
                                 in which case the data subject can enforce its rights against such entity.<br/>
                                 The data importer may not rely on a breach by a sub-processor of its obligations in order to avoid its own liabilities.
                            </li>
                            <li>
                                If a data subject is not able to bring a claim against the data exporter or the data importer
                                 referred to in paragraphs 1 and 2, arising out of a breach by the sub-processor of any of their obligations
                                 referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared
                                 or ceased to exist in law or have become insolvent, the sub-processor agrees that the data subject may issue a claim
                                 against the data sub-processor with regard to its own processing operations under the Clauses
                                 as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations
                                 of the data exporter or data importer by contract or by operation of law,
                                 in which case the data subject can enforce its rights against such entity.
                                 The liability of the sub-processor shall be limited to its own processing operations under the Clauses.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 7 - Mediation and jurisdiction</b>
                        <ol>
                            <li>
                                The data importer agrees that if the data subject invokes against it third-party beneficiary rights
                                 and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:
                                <ol type="a">
                                    <li>
                                        to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;
                                    </li>
                                    <li>
                                        to refer the dispute to the courts in the Member State in which the data exporter is established.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                The parties agree that the choice made by the data subject will not prejudice its substantive
                                 or procedural rights to seek remedies in accordance with other provisions of national or international law.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 8 - Cooperation with supervisory authorities</b><br/> 
                        <ol>
                            <li>
                                The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests
                                 or if such deposit is required under the applicable data protection law.
                            </li>
                            <li>
                                The parties agree that the supervisory authority has the right to conduct an audit of the data importer,
                                 and of any sub-processor, which has the same scope and is subject to the same conditions as would apply
                                 to an audit of the data exporter under the applicable data protection law.
                            </li>
                            <li>
                                The data importer shall promptly inform the data exporter about the existence of legislation applicable
                                 to it or any sub-processor preventing the conduct of an audit of the data importer, or any sub-processor,
                                 pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5(b).
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 9 - Governing law</b><br/> 
                        The Clauses shall be governed by the law of the Member State in which the data exporter is established.
                    </p>
                    <p>
                        <b>Clause 10 - Variation of the contract</b><br/> 
                        The parties undertake not to vary or modify the Clauses.
                         This does not preclude the parties from adding clauses on business related issues
                         where required as long as they do not contradict the Clause.
                    </p>
                    <p>
                        <b>Clause 11 - Sub-processing</b> 
                        <ol>
                            <li>
                                The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter
                                 under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts
                                 its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way
                                 of a written agreement with the sub-processor which imposes the same obligations on the sub-processor
                                 as are imposed on the data importer under the Clauses. Where the sub-processor fails to fulfil its data protection
                                 obligations under such written agreement the data importer shall remain fully liable to the data exporter
                                 for the performance of the sub-processor’s obligations under such agreement.
                            </li>
                            <li>
                                The prior written contract between the data importer and the sub-processor shall also provide for
                                 a third-party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring
                                 the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer
                                 because they have factually disappeared or have ceased to exist in law or have become insolvent and
                                 no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract
                                 or by operation of law. Such third-party liability of the sub-processor shall be limited to its own processing
                                 operations under the Clauses.
                            </li>
                            <li>
                                The provisions relating to data protection aspects for sub-processing of the contract referred to in paragraph 1
                                 shall be governed by the law of the Member State in which the data exporter is established.
                            </li>   
                            <li>
                                The data exporter shall keep a list of sub-processing agreements concluded under the Clauses and
                                 notified by the data importer pursuant to Clause 5(j), which shall be updated at least once a year.
                                 The list shall be available to the data exporter’s data protection supervisory authority. 
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Clause 12 - Obligation after the termination of personal data-processing services</b><br/> 
                        <ol>
                            <li>
                                The parties agree that on the termination of the provision of data-processing services,
                                 the data importer and the sub-processor shall, at the choice of the data exporter,
                                 return all the personal data transferred and the copies thereof to the data exporter
                                 or shall destroy all the personal data and certify to the data exporter that it has done so,
                                 unless legislation imposed upon the data importer prevents it from returning or destroying all
                                 or part of the personal data transferred. In that case, the data importer warrants that it will guarantee 
                                 the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.
                            </li>
                            <li>
                                The data importer and the sub-processor warrant that upon request of the data exporter and/or
                                 of the supervisory authority, it will submit its data-processing facilities for an audit of the measures
                                 referred to in paragraph 1.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>Appendix 1</b><br/> 
                        This Appendix forms part of the Standard Contractual Clauses (the 'Clauses').<br/>
                        Defined terms used in this Appendix shall have the meaning given to them in the Terms (including the DPA).
                        <ul>
                            <li>
                                Data exporter: The legal entity specified as "Customer" in the DPA. 
                            </li>
                            <li>
                                Data importer: Eliron Advisory, Inc.
                            </li>
                            <li>
                                Data subjects: See Annex 1 of the DPA
                            </li>
                            <li>
                                Categories of data: See Annex 1 of the DPA 
                            </li>
                            <li>
                                Special categories of data (if appropriate): None
                            </li>
                            <li>
                                Purposes of Processing: To provide the Services to data exporter in accordance with the Terms.  
                            </li>
                            <li>
                                Processing operations: See Annex 1 of the DPA
                            </li>
                        </ul>
                    </p>
                    <p>
                        <b>Appendix 2</b><br/> 
                        This Appendix forms part of the Standard Contractual Clauses (the 'Clauses').<br/>
                        Description of the technical and organisational security measures implemented by the data importer
                          in accordance with Clauses 4(d) and 5(c)(or document/legislation attached): <br/>
                        Please see Annex 2 of the DPA.
                    </p>
                    <p>
                        <b>Appendix 3</b><br/> 
                        This Appendix forms part of the Standard Contractual Clauses (the 'Clauses').<br/>
                        This Appendix sets out the parties' interpretation of their respective obligations under specific terms of the Clauses.
                         Where a party complies with the interpretations set out in this Appendix, that party shall be deemed by the other party
                         to have complied with its commitments under the Clauses. For the purposes of this Appendix, "DPA" means the 
                         Data Processing Agreement in place between Customer and Jensa Ventures Inc and
                         to which these Clauses are incorporated and "Terms" shall have the meaning given to it in the DPA.
                        <ol>
                            <li>
                                Clause 4(h) and 8: Disclosure of these Clauses<br/>
                                Data exporter agrees that these Clauses constitute data importer's Confidential Information as defined in the Terms
                                 and may not be disclosed by data exporter to any third party without data importer's prior written consent
                                 unless permitted pursuant to Terms.  This shall not prevent disclosure of these Clauses to a data subject
                                 pursuant to Clause 4(h) or a supervisory authority pursuant to Clause 8.
                            </li>
                            <li>
                                Clauses 5(a) and 5(b): Suspension of data transfers and termination<br/>
                                The parties acknowledge that data importer may process the personal data only on behalf of the data exporter
                                 and in compliance with its instructions as provided by the data exporter and the Clauses. The parties acknowledge
                                 that if data importer cannot provide such compliance in accordance with Clause 5(a) and Clause 5(b)
                                 for whatever reason, the data importer agrees to inform promptly the data exporter of its inability to comply,
                                 in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract
                                 the affected parts of the Services in accordance with the Terms. If the data exporter intends to suspend
                                 the transfer of personal data and/or terminate the affected  parts of the Services,
                                 it shall endeavour to provide notice to the data importer and provide data importer with a reasonable period of time
                                 to cure the non-compliance (“Cure Period”). If required, the parties shall reasonably cooperate with each other
                                 during the Cure Period to agree what additional safeguards or other measures, if any,
                                 may be reasonably required to ensure the data importer's compliance with the Clauses and applicable data protection law.
                                 If after the Cure Period the data importer has not or cannot cure the non-compliance then
                                 the data exporter may suspend and/or terminate the affected part of the Services in accordance with the provisions
                                 of the Terms without liability to either party (but without prejudice to any fees incurred by the data exporter
                                 prior to suspension or termination). The data exporter shall not be required to provide such notice in instance
                                 where it considers there is a material risk of harm to data subjects or their personal data.
                            </li>
                            <li>
                                Clause 5(f): Audit<br/>
                                The parties agree that data importer will make all information reasonably necessary to demonstrate compliance
                                 with this DPA available to data exporter. Data exporter agrees to exercise audit rights as described below:
                                <ul>
                                    <li>
                                        Data exporter acknowledges that the Service is hosted by cloud infrastructure partners who maintain independently
                                         validated security programs (including SOC 2 and ISO 27001). Upon request, data importer will supply 
                                         (on a confidential basis) any relevant security reports provided by their cloud infrastructure partners. 
                                    </li>
                                    <li>
                                        Further, at data exporters written request, data importer will provide written responses
                                         (on a confidential basis) to all reasonable requests for information necessary
                                         to confirm data importer's compliance with this DPA, provided that data exporter will not exercise this right
                                         more than once per calendar year.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Clause 5(j): Disclosure of subprocessor agreements<br/>
                                <ul>
                                    <li>
                                        The parties acknowledge the obligation of the data importer to send promptly a copy of any onward
                                         subprocessor agreement it concludes under the Clauses to the data exporter. 
                                    </li>
                                    <li>
                                        The parties further acknowledge that, pursuant to subprocessor confidentiality restrictions,
                                         data importer may be restricted from disclosing onward subprocessor agreements to data exporter.
                                        Notwithstanding this, data importer shall use reasonable efforts to require any subprocessor it appoints
                                         to permit it to disclose the subprocessor agreement to data exporter.
                                    </li>
                                    <li>
                                        Where data importer cannot disclose a subprocessor agreement to data exporter,
                                         the parties agree that, upon the request of data exporter, data importer shall provide 
                                         publicly available privacy policies and terms of service of the subprocessor to data exporter. 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Clause 6: Liability<br/>
                                Any claims brought under the Clauses shall be subject to the Terms, including but not limited to,
                                 the exclusions and limitations set forth in the Terms.  In no event shall the data exporter limit its liability
                                 with respect to any data subject rights under these Clauses.
                            </li>
                            <li>
                                Clause 11: Onward subprocessing<br/>
                                The parties acknowledge that, pursuant to FAQ II.1 in Article 29 Working Party Paper WP 176 entitled
                                 "FAQs in order to address some issues raised by the entry into force of the EU Commission Decision
                                 2010/87/EU of 5 February 2010 on standard contractual clauses for the transfer of personal data to
                                 processors established in third countries under Directive 95/46/EC" the data exporter may provide
                                 a general consent to onward subprocessing by the data importer. Accordingly, data exporter provides
                                 a general consent to data importer, pursuant to Clause 11 of these Clauses, to engage onward subprocessors.
                            </li>
                            <li>
                                Clause 12: Obligation after the termination of personal data-processing services<br/>
                                Data importer agrees that the data exporter will fulfil its obligation to return or destroy all the personal data
                                 on the termination of the provision of data-processing services by complying with the
                                 'Deletion of Personal Data' section of the DPA. 
                            </li>
                        </ol>
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            The sub-processors we use.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Annex 4 - Sub-processors</SegmentSubTitle>
                    <p>
                        <ul>
                            <li>
                                Amazon Web Services - Cloud Compute, Storage and Authorization Infrastructure 
                            </li>
                            <li>
                                Google - Authentication, Analytics, Communication and Collaboration
                            </li>
                            <li>
                                Stripe - Payment Processing
                            </li>
                            <li>
                                Twilio - Email and Text Notifications
                            </li>
                            <li>
                                Netlify - Hosting and DNS
                            </li>
                            <li>
                                Sentry - Error Monitoring
                            </li>
                        </ul> 
                    </p>
                </Segment>
                <TextDiv 
                    color={theme.colors.extraSubtleText} 
                    marginTop="20px" 
                    marginBottom="20px" 
                    breakpoint={theme.breakpoints.mobile}
                    responsiveAlign="center"
                >
                    Last updated on Feb 19, 2022.
                </TextDiv>
            </Section>
        </SiteLayout>    
    )
}

export default Dpa
